.watermarkContainer {
    position: relative;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: left;
    width: 200px;
    height: 200px;
}

.watermarkButtons {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-between;
    top: 0;
    z-index: 2;
}

.imageWrapper {
    position: relative;
    border: 2px solid #999;
    overflow: hidden;
    width: 180px;
    height: 180px;
    border-radius: 20px;
}

.iconX {
    width: 13px;
    height: 13px;
    margin-top: -7px;
}

.closeButton {
    box-sizing: content-box;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    z-index: 10;
    padding: 0;
    color: #000;
    border: 1px solid #999;
    border-radius: 50px;
    background-color: #fff;
    cursor: pointer;
}

.uploadButton {
    box-sizing: content-box;
    width: 30px;
    height: 30px;    
    color: #000;
    border: 1px solid #999;
    border-radius: 50px;
    background-color: white;
}

.iconUpload {
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.inputImage {
    display: none!important;
}

.imageFitCover {
    object-fit: cover;
    width: 100%;
    padding: 5px;
}

.watermarkPosition {
    width: 30%;
    margin: 0 20px 0 20px;
}

.watermarkTransparency {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
}