.delayedShow {
    height: 1px;
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
    transition-delay: 0.2s; /* Set the delay here */
}

.delayedShow.show {
    margin-top: 5px;
    opacity: 1;
}

.delayedTextColor {
    color: #2c3e50; /* Initial text color */
    transition: color 0.1s ease-in-out; /* Animate text color */
}

.delayedTextColor.error {
    color: #e82c2c !important;
}
