.real-estate-form-tabs {
    .nav-item {
        &:after {
            content: '';
            display: block;
            height: rem(1);
            width: 100%;
            background: #e7e9eb;
        }
        &.active {
            &:after {
                background: $success;
                height: rem(3);
                transform: matrix(-1, 0, 0, 1, 0, 0);
            }
        }
    }
    .real-estate-form-nav-link {
        padding: rem(23) rem(13.25);
        border: none;
        font-size: rem(14);
        font-weight: 400;
        color: #152028;

        &.active {
            background-color: transparent;
            border: none;
            color: $success;
        }
    }
}

.offer-print-modal-tabs {
    .nav-item {
        &:after {
            content: '';
            display: block;
            // height: rem(1);
            // width: 100%;
            // background: #E7E9EB;
        }
        &.active {
            &:after {
                margin-bottom: -2px;
                background: $success;
                height: rem(3);
                transform: matrix(-1, 0, 0, 1, 0, 0);
            }
        }
    }
    .real-estate-form-nav-link {
        padding: rem(15) rem(12);
        border: none;
        font-size: rem(14);
        font-weight: 400;
        color: #152028;

        &.active {
            background-color: transparent;
            border: none;
            color: $success;
        }
    }
}

.language-input-tabs {
    border: 1px solid #d4d9de;
    border-bottom: none;
    border-radius: rem(4) rem(4) 0 0;
    .nav-item {
        &:after {
            content: '';
            display: block;
            height: rem(1);
            width: 100%;
            background: #e7e9eb;
        }
        &.active {
            &:after {
                background: $edit;
                height: rem(3);
                transform: matrix(-1, 0, 0, 1, 0, 0);
            }
        }
    }
    .real-estate-form-nav-link {
        padding: rem(17) rem(16);
        border: none;
        font-size: rem(13);
        font-weight: 400;
        color: #152028;

        &.active {
            background-color: transparent;
            border: none;
            color: $edit;
        }
    }
}

.real-estate-form-subtabs {
    .nav-item {
        &:not(:first-of-type) {
            margin-left: rem(10);
        }
    }
    .real-estate-form-nav-link {
        border: 1px solid #ced4da;
        padding: rem(8) rem(20);
        font-size: rem(13);
        font-weight: 400;
        color: 273B4A;

        &.active {
            background-color: #2596d6;
            border-color: #2596d6;
            color: white;
        }
    }
}

.detail-view-tabs {
    .nav-item {
        &:not(:first-of-type) {
            margin-left: rem(10);
        }
    }
    .real-estate-form-nav-link {
        border: 1px solid $white;
        background-color: $white;
        padding: rem(8) rem(20);
        font-size: rem(13);
        font-weight: 400;
        color: $primary;

        &.active {
            background-color: $primary;
            border-color: $primary;
            color: white;
        }
    }
}

.property-view-materils-tabs {
    .real-estate-form-nav-link {
        background-color: transparent;
        color: $white;
        font-size: rem(21);
        padding: rem(5) rem(8);

        &.active {
            background-color: transparent;
            border-color: transparent;
            color: red;
        }

        &:hover {
            border-color: transparent;
        }
    }
}

.form-label {
    font-weight: 300;
    color: #273b4a;
}

.table .dmn-table-header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 5;

    th {
        height: 100%;
        padding: 12px 11px;
        .form-check {
            min-height: 1rem;
        }
        div {
            height: 100%;
        }
        &:not(:first-of-type):not(:last-of-type) {
            padding-right: 0px;
            div {
                padding-right: 11px;
                border-right: 1px solid rgba(255, 255, 255, 0.2);
            }
        }
        background: $primary;
        color: #fff;
        border: none;
        width: auto;
        font-weight: 500;
        font-size: 13px;
        letter-spacing: 0.2px;
        white-space: nowrap;
        cursor: initial;

        &:first-of-type {
            width: 40px;
            max-width: 40px;
            min-width: 40px;
            padding: 0;
        }

        &:first-of-type > * {
            width: 100%;
            justify-content: center !important;
            border-right: 1px solid rgba(255, 255, 255, 0.2);
            margin: 12px 0;
            padding: 0 12px 2px 12px;
        }

        &.is-filterable {
            cursor: pointer;
        }

        &#id {
            &.is-filterable {
                max-width: 50px;
            }
        }
        &#contactPerson_firstName-lastName {
            // width: 12%;
            width: 140px;
            min-width: 140px;
            max-width: 140px;
        }
        &#contactCompany_name {
            // width: 12%;
            width: 170px;
            min-width: 170px;
            // max-width: 140px;;
        }
        &#contactStatus {
            width: 185px;
            min-width: 185px;
            max-width: 185px;
        }
        &#street {
            width: 200px;
            min-width: 200px;
            max-width: 200px;
        }
        &#streetNumber {
            width: 110px;
            min-width: 110px;
            // max-width: 100px;
        }
        &#city {
            width: 120px;
            min-width: 120px;
            max-width: 120px;
        }
        &#initialContactDate {
            width: 230px;
            min-width: 230px;
            max-width: 230px;
        }
        &#updated {
            width: 160px;
            min-width: 160px;
            // max-width: 140px;
        }
        &#created {
            width: 155px;
            min-width: 155px;
            // max-width: 115px;
        }
        &#contactPhone1,
        &#contactPhone2 {
            width: 170px;
            min-width: 170px;
            max-width: 170px;
        }
        &#dataSource {
            width: 200px;
            min-width: 200px;
            max-width: 200px;
        }
        &#contactEmial {
            width: 150px;
            min-width: 150px;
            max-width: 150px;
        }
        &#contactType {
            width: 150px;
            min-width: 150px;
            max-width: 150px;
            &.is-filterable {
                max-width: 175px;
            }
        }
        &#contactDefinition {
            width: 170px;
            min-width: 170px;
            // max-width: 150px;
        }
        &#projectStatus {
            width: 130px;
            min-width: 130px;
            max-width: 130px;
            &.is-filterable {
                max-width: 140px;
            }
        }
        &#projectObjectType {
            width: 225px;
            min-width: 225px;
            max-width: 225px;
        }
        &#projectTitle {
            width: 250px;
            min-width: 250px;
        }
        &#city_county {
            width: 180px;
            min-width: 180px;
            max-width: 180px;
        }
        &#widerArea {
            width: 130px;
            min-width: 130px;
            max-width: 130px;
        }
        &#quarter {
            width: 110px;
            min-width: 110px;
            max-width: 110px;
            &.is-filterable {
                max-width: 120px;
            }
        }
        &#agent {
            width: 160px;
            min-width: 160px;
            max-width: 160px;
        }
        &#contractDate {
            width: 190px;
            min-width: 190px;
            max-width: 190px;
        }
        &#contractNo {
            width: 110px;
            min-width: 110px;
            max-width: 110px;
            &.is-filterable {
                max-width: 125px;
            }
        }
        &#projectType {
            width: 140px;
            min-width: 140px;
            max-width: 140px;
        }
        // TODO: remove if not needed
        // &#projectEndDate {
        //   width: 230px;
        //   min-width: 230px;
        //   max-width: 230px;
        // }
        // &#projectSize {
        //   width: 130px;
        //   min-width: 130px;
        //   max-width: 130px;
        // }
        // &#projectDevelopmentStage {
        //   width: 150px;
        //   min-width: 150px;
        //   max-width: 150px;
        // }
        &#internalId {
            width: 100px;
            min-width: 100px;
            max-width: 100px;
            &.is-filterable {
                max-width: 120px;
            }
        }
        &#propertyService {
            width: 130px;
            min-width: 130px;
            max-width: 130px;
            &.is-filterable {
                max-width: 145px;
            }
        }
        &#propertyStatus {
            width: 150px;
            min-width: 150px;
            // max-width: 130px;
        }
        &#price {
            width: 130px;
            min-width: 130px;
        }
        &#priceM2 {
            width: 130px;
            min-width: 130px;
        }
        &#maxConstructionArea {
            width: 100px;
            min-width: 100px;
        }
        &#agentPhoneNumber {
            width: 140px;
            min-width: 140px;
        }
        &[id^='owner'] {
            width: 140px;
            min-width: 140px;
        }
        &#contact_name {
            width: 160px;
            min-width: 160px;
        }
        &#contact_number {
            width: 180px;
            min-width: 180px;
        }
        &#area {
            width: 140px;
            min-width: 140px;
            max-width: 140px;
        }
        &#floorNo {
            width: 170px;
            min-width: 170px;
            max-width: 170px;
        }
        &#bedroomsNo {
            width: 160px;
            min-width: 160px;
            max-width: 160px;
            &.is-filterable {
                max-width: 175px;
            }
        }
        &#propertyRoomsNo {
            width: 90px;
            min-width: 90px;
            max-width: 90px;
        }
        &#propertyType {
            width: 150px;
            min-width: 150px;
        }
        &#distanceSea {
            width: 170px;
            min-width: 170px;
            max-width: 170px;
        }
        &#areaGarden {
            width: 150px;
            min-width: 150px;
            max-width: 150px;
        }
        &#plotArea {
            width: 145px;
            min-width: 145px;
            max-width: 145px;
        }
        &#propertyHeating {
            width: 140px;
            min-width: 140px;
            max-width: 140px;
        }
        &#constructionYear {
            width: 140px;
            min-width: 140px;
            max-width: 140px;
            &.is-filterable {
                max-width: 155px;
            }
        }
        &#availableFromDate {
            width: 190px;
            min-width: 190px;
            max-width: 190px;
            &.is-filterable {
                max-width: 205px;
            }
        }
        &#propertyReceiptDate {
            width: 130px;
            min-width: 130px;
            max-width: 130px;
            &.is-filterable {
                max-width: 145px;
            }
        }
        &#contactProfileType {
            width: 180px;
            min-width: 180px;
            // max-width: 190px;
        }
        &#contactProfileStatus {
            width: 200px;
            min-width: 200px;
            // max-width: 190px;
        }
        &#contactPersonCompany {
            width: 150px;
            min-width: 150px;
            // max-width: 190px;
        }
        &#latestActivityDate {
            width: 210px;
            min-width: 210px;
            // max-width: 190px;
        }
        &#nextActivityDate {
            width: 220px;
            min-width: 220px;
            // max-width: 190px;
        }
        &#demandPriceTo {
            width: 170px;
            min-width: 170px;
            // max-width: 190px;
        }
        &#contactActivityType {
            width: 140px;
            min-width: 140px;
            // max-width: 190px;
        }
        &#lastActivityComment {
            width: 230px;
            min-width: 230px;
            // max-width: 190px;
        }
        &#contactEmail {
            width: 150px;
            min-width: 150px;
            // max-width: 190px;
        }
        &:last-of-type {
            width: 87px;
        }
    }
}

.table {
    overflow: auto;
    img {
        width: 50px;
        height: 40px;
    }
}

.table-responsive {
    height: calc(100vh - 330px);
    position: relative;
    z-index: 0;
}

table.dmn-table--property-documents {
    th {
        &:first-of-type {
            width: 30px;
            max-width: 30px;
            min-width: 30px;
        }
        &#id {
            width: 35px;
            max-width: 35px;
            min-width: 35px;
        }
        // &#fileName {
        //     width: 210px;
        //     min-width: 210px;
        // }
        &#documentType {
            width: 140px;
            min-width: 140px;
        }
        &#documentPropertyId,
        &#documentProjectId {
            width: 120px;
            min-width: 120px;
        }
        &#documentContactId {
            width: 150px;
            min-width: 150px;
        }
        &#documentCreationDate {
            width: 140px;
            min-width: 140px;
        }
        &#documentValidityDate {
            width: 135px;
            min-width: 135px;
        }
    }
}

.pagination {
    margin-bottom: 0;
    .page-item:not(:first-of-type) {
        margin-left: rem(5);
    }
}

.required-mark {
    color: #d22828;
    font-weight: 300;
}

.nav-sync-data {
    display: flex;
    align-items: center;
    span {
        display: inline-block;
        &:not(:first-of-type) {
            padding-left: rem(14);
            border-left: 1px solid rgba(255, 255, 255, 0.4);
        }
        &:not(:last-of-type) {
            padding-right: rem(14);
        }
    }
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
    background-color: $primary-hover !important;
}

.dmn-card {
    min-height: 531px;
}

.dmn-card--main {
    min-height: calc(531px - 97px);
}

.dropzone {
    min-height: 0;
    background-color: #fff;
    border: 1px dashed #808080;
    border-radius: 4px;
    color: $primary;
    .dz-message {
        margin: rem(47) 0;
    }
}

.dz-queue {
    opacity: 0.5;
}
.dz-uploading {
    border: 4px solid #16a0bc;
}

.image-template {
    border: 3px solid #fff;
}

.image-selected {
    border: 3px solid $success;
}

.tabbed-input {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: 0;
}

.swiper-button-next::after,
.swiper-button-prev::after {
    font: normal normal normal 24px/1 'Material Design Icons' !important;
    font-size: inherit !important;
    text-rendering: inherit impora !important;
    font-size: rem(17) !important;
    line-height: rem(17) !important;
    color: $white !important;
}

.swiper-button-prev::after {
    content: '\F0141' !important;
}

.swiper-button-next::after {
    content: '\F0142' !important;
}

.swiper-button-next,
.swiper-button-prev {
    background: $primary !important;
    color: $white !important;
}

.fc-event-title,
.fc-event-title {
    font-weight: 500 !important;
}

.offer-properties-list {
    border-top: 1px solid #e7e9eb;
    // border-bottom: 1px solid black;
    .offer-property-item {
        // border-bottom: 1px solid black;
        border-bottom: 1px solid #e7e9eb;
    }
}

.dmn-search-dropdown-menu {
    width: 560px;
}

.dmn-divider {
    height: 1px;
    width: 100%;
    background: #e7e9eb;
    // background: black;
}

.dmn-photo-sync-icon {
    position: absolute;
    z-index: 10;
    bottom: 10px;
    left: 10px;
    background: white;
    width: rem(26);
    height: rem(26);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &.dmn-photo-synced {
        background-color: $success;
        i {
            color: white;
        }
    }
    i {
        color: #bfbfbf;
        font-size: rem(17);
    }
}

.dmn-drag-row {
    div[role='presentation'] {
        display: flex !important;
        flex-wrap: wrap;
    }
    div[role='presentation'] > * {
        max-width: 100%;
        padding-right: calc(var(--dmn-gutter-x) * 0.5);
        padding-left: calc(var(--dmn-gutter-x) * 0.5);
        margin-top: var(--dmn-gutter-y);
    }
}

.btn-contact-activity {
    border-color: #ced4da;
}

.activity-loader-container {
    position: absolute;
    background: rgba(255, 255, 255, 0.5);
    top: calc(37.5px + 1rem);
    left: 1rem;
    height: calc(100% - 37.5px - 2rem);
    width: calc(100% - 2rem);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}

.fc-event {
    text-align: left;
}

.form-check-input.activity-status-check-input:checked {
    background-color: $success;
    border-color: $success;
}

.full-page-loader {
    height: 100vh;
    .spinner-border {
        width: 4rem;
        height: 4rem;
    }
}

.dmn-photo-card {
    .card-img {
        display: flex;
        height: 140px;
    }

    img {
        margin: auto;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
}

.is-invalid {
    [class$='-control'] {
        border-color: $danger;
    }
}

.dmn-sorting-icon {
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    font-size: 10px;

    i {
        opacity: 0.25;

        &.active {
            opacity: 1;
        }
    }
}

.export-sync-controls {
    color: #fff;

    .btn {
        font-size: 16px;
        color: inherit !important;
        display: flex;
        align-items: center;
        gap: 4px;
        line-height: 24px;
    }

    i {
        font-size: 24px;
    }

    > span:first-of-type {
        font-weight: 500;
    }
}

.dmn-link {
    color: $send;
    text-decoration: underline !important;
}

.flatpicker-container {
    position: relative;
}

.flatpicker-clear {
    position: absolute;
    right: calc(var(--dmn-gutter-x) * 0.5);
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.dropdown-menu-form {
    padding: 22px;
}

.dmn-collapse-card {
    .accordion-button {
        &:after {
            content: none;
        }
    }
}

.table-striped {
    tbody tr:nth-of-type(even) {
        background-color: #eff0f1;
    }
}

.accordion-item-disabled {
    opacity: 0.5;
    pointer-events: none;
}

.dmn-colorpicker {
    border: 1px solid #ced4da;
    padding: rem(8);
    border-radius: 4px;
    position: relative;
    .sketch-picker {
        position: absolute;
        bottom: 100%;
        left: 0;
        z-index: 10;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }
}

.crop-direction-row {
    display: flex;
    flex-direction: row;
    gap: 3px;
    align-items: center;
    .crop-direction-rect {
        width: 10px;
        height: 10px;
        border: 1px solid #ced4da;
        cursor: pointer;
        margin-bottom: 3px;
        &.active {
            background-color: $primary;
            border-color: $primary;
        }
    }
}

.large-spinner-container {
    height: 300px;
    .spinner-border {
        width: 3rem;
        height: 3rem;
    }
}

// tinyMCE logo
.tox .tox-statusbar__branding svg {
    display: none;
}

// ReactStrap carousel class
.carousel-inner {
    display: flex;
    justify-content: center;
}

.swiper-pagination-fraction {
    height: 35px;
    padding: 10px;
    font-size: 12px;
    width: auto !important;
    background-color: rgba(39, 59, 74, 1);
    backdrop-filter: unset;
    top: 10px;
    left: unset !important;
    right: 55px;
    border-radius: 0.25rem;
}

@media (max-width: 1400px) {
    .drag-node {
        width: 100%;
    }

    .dmn-photo-card {
        .card-img {
            border: none;
            height: 250px;
        }
    }
}
